import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { checkScholarshipFirstFollowUp } from '../../../../api/Scholarship/scholarshipTracking';
import { APIValidationError } from '../../../../api/api';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import TransitionsModal from '../../../../components/Modal/TransitionModal';
import Title from '../../../../components/Title/Title';
import { useButtonAction } from '../../../../helpers/customHooks/useHookMethod';
import useValidatorAPI from '../../../../helpers/customHooks/useValidatorAPI';
import { dbDateToDate } from '../../../../helpers/dateHelper';
import { tKey, tScholarshipKey } from '../../../../helpers/translate';
import { DocumentationItem, IdTypeCodeNamePair, KeyButton } from '../../../../redux/common/definitions';
import { GenericMetadata } from '../../../../redux/metadata/definitions';
import { uploadDocument } from '../../../../redux/scholarship/actions';
import {
  KeepStudyingOption,
  ScholarshipAbandonmentReasonsType,
  ScholarshipData,
  ScholarshipFollow,
  ScholarshipMetadata,
  ScholarshipStateChange,
  ScholarshipStateCode,
} from '../../../../redux/scholarship/definitions';
import FollowUpCode from '../../../../types/Scholarships/followUpCode';
import { getButtonType } from '../../ButtonActions/ScholarshipButtonActions';
import TransversalCompetencesSection from '../../Components/TransversalCompetencesSection';
import AcademicResultView from './AcademicResult/AcademicResultView';
import AcademyValoration from './AcademyValoration';
import FuturePlans from './FuturePlans';
import LaboralImpact from './LaboralImpact';
import MessageFollow from './MessageFollow';
import ScholarshipZing from './ScholarshipZing';
import SocialImpact from './SocialImpact';
import styles from './followForm.module.scss';
import {
  entityFollowUpSchema,
  instituteFollowUpSchema,
  lastFollowUpSchema,
} from '../../../../validations/scholarshipFollowUpSchema';
import ScholarshipAbandonmentReasonCode from '../../../../types/Scholarships/ScholarshipAbandonmentReasonCode';

interface FollowFormProps {
  scholarshipData: ScholarshipData;
  scholarshipFollow: ScholarshipFollow | null;
  scholarshipMetadata: ScholarshipMetadata;
  genericMetadata: GenericMetadata;
  documentationUN: DocumentationItem[];
  documentationWork: DocumentationItem[];
  readOnly: boolean;
  validationErrors: APIValidationError | null;
  followUpCode: FollowUpCode;
  isNousCims: boolean;
  canTrackingTransition: boolean;
  addDoc: (document: File, description: string, genericId?: number, documentation_id?: number) => void;
  uploadDocumentTracking: (documentation_id: number, data: File, scholarshipId: number, tracking_id: number) => void;
  downloadDocument?: (documentation_id: number, name: string, scholarship_id: number) => void;
  getFile?: (documentation_id: number, name: string, scholarship_id: number) => void;
  deleteDocument?: (documentation_id: number, scholarship_id: number) => void;
  setDataFollow: (subKey: string, value: string | number | null) => void;
  onChangeState: (states: ScholarshipStateChange) => void;
  saveFollowUp: (follow_up: ScholarshipFollow) => void;
}

const FollowForm: React.FC<FollowFormProps> = ({
  scholarshipData,
  scholarshipFollow,
  scholarshipMetadata,
  genericMetadata,
  documentationUN,
  readOnly,
  validationErrors,
  followUpCode,
  documentationWork,
  canTrackingTransition,
  isNousCims,
  addDoc,
  saveFollowUp,
  onChangeState,
  uploadDocumentTracking,
  downloadDocument,
  getFile,
  deleteDocument,
  setDataFollow,
}) => {
  const { isEntity, state_code } = scholarshipData;
  const scholarshipType = isEntity ? 'entity' : 'institute';
  const [paymentDateModal, setPaymentDateModal] = useState<string | null>(null);
  const { scholarshipAbandonmentReasons, scholarshipFutureActions } = scholarshipMetadata;

  const showLastFollowUpForm = (): boolean => {
    if (followUpCode.isLastFollowUp()) {
      return true;
    }

    if (scholarshipFollow) {
      const { keep_studying, scholarship_abandoment_reasons_id } = scholarshipFollow;
      if (keep_studying !== KeepStudyingOption.SI) {
        if (!scholarship_abandoment_reasons_id) {
          return true;
        }
        const abandonmentReason = scholarshipAbandonmentReasons.filter(
          (item: IdTypeCodeNamePair<ScholarshipAbandonmentReasonsType>) =>
            item.id === scholarship_abandoment_reasons_id,
        );
        const abandonmentReasonCode = new ScholarshipAbandonmentReasonCode(abandonmentReason[0].code);
        return !abandonmentReasonCode.isDesvinculacion();
      }
    }
    return false;
  };

  const selectSchema = () => {
    return showLastFollowUpForm()
      ? lastFollowUpSchema(scholarshipFutureActions)
      : !!isEntity
      ? entityFollowUpSchema
      : instituteFollowUpSchema;
  };

  const nextState = {
    [ScholarshipStateCode.FOLLOW_T1 as string]: 's12s24' as ScholarshipStateChange,
    [ScholarshipStateCode.FOLLOW_T2 as string]: 's13s25' as ScholarshipStateChange,
    [ScholarshipStateCode.FOLLOW_T3 as string]: 's14s26' as ScholarshipStateChange,
    [ScholarshipStateCode.FOLLOW_S1 as string]: 's15s22' as ScholarshipStateChange,
    [ScholarshipStateCode.FOLLOW_S2 as string]: 's16s23' as ScholarshipStateChange,
  };

  const { errors, setValue, register, setError, control, handleSubmit } = useForm<ScholarshipFollow>({
    mode: 'onSubmit',
    submitFocusError: true,
    validationSchema: selectSchema(),
  });

  useValidatorAPI(validationErrors, setError);

  const hideSendButton = (): boolean => {
    if (followUpCode.isFirstFollowUp()) {
      return state_code !== ScholarshipStateCode.FOLLOW_S1 && state_code !== ScholarshipStateCode.FOLLOW_T1;
    }
    if (followUpCode.isSecondFollowUp()) {
      return state_code !== ScholarshipStateCode.FOLLOW_S2 && state_code !== ScholarshipStateCode.FOLLOW_T2;
    }
    if (followUpCode.isThridFollowUp()) {
      return state_code !== ScholarshipStateCode.FOLLOW_T3;
    }
    return true;
  };

  const onChange = async () => {
    if (followUpCode.isFirstFollowUp()) {
      const paymnet_date = await checkScholarshipFirstFollowUp(scholarshipData.id);
      if (paymnet_date) {
        setPaymentDateModal(paymnet_date);
        return;
      }
    }
    onChangeState(nextState[state_code]);
  };

  const onSave = () => {
    if (scholarshipFollow) {
      saveFollowUp(scholarshipFollow);
    }
  };

  const buttonActionSave = getButtonType(KeyButton.SAVE_FOLLOW);
  const buttonActionSend = getButtonType(KeyButton.SEND_FOLLOW);
  buttonActionSave.hidden = readOnly;
  buttonActionSend.hidden = hideSendButton() || !canTrackingTransition;

  useButtonAction(buttonActionSend, handleSubmit(onChange));
  useButtonAction(buttonActionSave, onSave);

  if (!scholarshipFollow) {
    return <></>;
  }

  const handleUploadDocument = (
    document: File,
    description: string,
    scholarshipId?: number,
    documentation_id?: number,
  ) => {
    if (documentation_id && scholarshipId)
      uploadDocumentTracking(documentation_id, document, scholarshipId, scholarshipFollow.id);
  };

  const renderAcedemicResult = (): JSX.Element => {
    return (
      <AcademicResultView
        scholarshipFollow={scholarshipFollow}
        scholarshipData={scholarshipData}
        scholarshipMetadata={scholarshipMetadata}
        scholarshipType={scholarshipType}
        documentationUN={documentationUN}
        readOnly={readOnly}
        errors={errors}
        control={control}
        schema={selectSchema()}
        downloadDocument={downloadDocument}
        getFile={getFile}
        deleteDocument={deleteDocument}
        addDoc={handleUploadDocument}
        setDataFollow={setDataFollow}
        register={register}
        followUpCode={followUpCode}
        setValue={setValue}
      />
    );
  };

  const renderLaboralImpact = (): JSX.Element => {
    return (
      <LaboralImpact
        scholarshipFollow={scholarshipFollow}
        scholarshipMetadata={scholarshipMetadata}
        readOnly={readOnly}
        scholarshipType={scholarshipType}
        setDataFollow={setDataFollow}
        register={register}
        errors={errors}
        control={control}
        scholarshipData={scholarshipData}
        genericMetadata={genericMetadata}
        downloadDocument={downloadDocument}
        getFile={getFile}
        deleteDocument={deleteDocument}
        documentationWork={documentationWork}
        upload={uploadDocument}
        addDoc={addDoc}
      />
    );
  };

  const renderSocialImpact = (): JSX.Element => {
    return (
      <SocialImpact
        scholarshipFollow={scholarshipFollow}
        scholarshipMetadata={scholarshipMetadata}
        readOnly={readOnly}
        errors={errors}
        control={control}
        setDataFollow={setDataFollow}
        schema={selectSchema()}
      />
    );
  };

  const renderAcademicValoration = (): JSX.Element => {
    return (
      <AcademyValoration
        scholarshipFollow={scholarshipFollow}
        scholarshipMetadata={scholarshipMetadata}
        readOnly={readOnly}
        errors={errors}
        control={control}
        register={register}
        schema={selectSchema()}
        setDataFollow={setDataFollow}
      />
    );
  };

  const renderScholarshipZing = (): JSX.Element => {
    return (
      <ScholarshipZing
        scholarshipFollow={scholarshipFollow}
        scholarshipMetadata={scholarshipMetadata}
        readOnly={readOnly}
        errors={errors}
        control={control}
        schema={selectSchema()}
        setDataFollow={setDataFollow}
        register={register}
      />
    );
  };

  const renderFuturePlans = (): JSX.Element => {
    return (
      <FuturePlans
        scholarshipFollow={scholarshipFollow}
        scholarshipMetadata={scholarshipMetadata}
        readOnly={readOnly}
        errors={errors}
        control={control}
        schema={selectSchema()}
        setDataFollow={setDataFollow}
      />
    );
  };

  const renderLastFollowUpQuestions = (): JSX.Element | undefined => {
    if (showLastFollowUpForm()) {
      return (
        <>
          {renderSocialImpact()}
          {renderAcademicValoration()}
          {renderScholarshipZing()}
          {renderFuturePlans()}
        </>
      );
    }
    return undefined;
  };

  const renderTransversalCompetencesView = (): JSX.Element | undefined => {
    if (
      (isEntity &&
        followUpCode.isLastFollowUp() &&
        (state_code === ScholarshipStateCode.FOLLOW_S2 || state_code === ScholarshipStateCode.FOLLOW_T3)) ||
      (scholarshipFollow.keep_studying === KeepStudyingOption.FIN &&
        (state_code === ScholarshipStateCode.FOLLOW_S1 ||
          state_code === ScholarshipStateCode.FOLLOW_T1 ||
          state_code === ScholarshipStateCode.FOLLOW_T2))
    ) {
      return <TransversalCompetencesSection isFinal scholarshipData={scholarshipData} />;
    }
    return undefined;
  };

  const renderTrackingComments = (): JSX.Element | undefined => {
    if (isNousCims) {
      return (
        <FormContainer
          title={tScholarshipKey('Observaciones')}
          subtitle={tScholarshipKey('Aquí puedes indicar observaciones internas')}
        >
          <TextInputController
            control={control}
            name="trackings_comments"
            defaultValue={scholarshipFollow.trackings_comments}
            size="100"
            schema={selectSchema()}
            errors={errors}
            multiline
            rows={5}
            rowsMax={5}
            disabled={readOnly}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => setDataFollow('trackings_comments', e.target.value)}
          />
        </FormContainer>
      );
    }
    return undefined;
  };

  const renderFollowUpModal = (): JSX.Element | undefined => {
    if (paymentDateModal) {
      const dateFormatted = dbDateToDate(paymentDateModal);
      const title = tScholarshipKey('followup.check.title');
      const body = `${tScholarshipKey('followup.check.body')} ${dateFormatted}`;
      return (
        <TransitionsModal
          view={!!paymentDateModal}
          handleClose={() => setPaymentDateModal(null)}
          title={title}
          bodyModal={body}
          buttonOk={tKey('OK')}
          buttonKo={tKey('Cancelar')}
          handleYes={() => {
            onChangeState(nextState[state_code]);
            setPaymentDateModal(null);
          }}
        />
      );
    }
    return undefined;
  };

  return (
    <form className={styles.form}>
      <Title>{`${tScholarshipKey('SEGUIMIENTO')} ${followUpCode.value()}`}</Title>
      <MessageFollow followUpCode={followUpCode} isEntity={isEntity} />
      {renderAcedemicResult()}
      {renderLaboralImpact()}
      {renderLastFollowUpQuestions()}
      {renderTransversalCompetencesView()}
      {renderTrackingComments()}
      {renderFollowUpModal()}
    </form>
  );
};

export default FollowForm;
