/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form';
import { connect } from 'react-redux';
import { ObjectSchema, Shape } from 'yup';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../helpers/translate';
import { AcademySelectItem } from '../../../../redux/academy/definitions';
import { scholarshipSetData } from '../../../../redux/scholarship/actions';
import { ScholarshipData, ScholarshipMetadata, ScholarshipType } from '../../../../redux/scholarship/definitions';
import StudyCenterNature from '../../../../types/Scholarships/StudyCenterNature';
import StudyCenterSelector from '../requestForms/StudyCenterSelector';
import styles from './confirmationForm.module.scss';
import { selectGetScholarshipMetadata } from '../../../../redux/scholarship/selectors';
import { AppState } from '../../../../redux/root-reducer';
import { CodeNamePair, IdCodeNamePair, IdNamePair } from '../../../../redux/common/definitions';
import { selectGenericMetadata } from '../../../../redux/metadata/selectors';

interface StudiesConfirmationFormProps<T extends FieldValues> {
  scholarshipData: ScholarshipData;
  scholarshipMetadata: ScholarshipMetadata;
  provinces: CodeNamePair[];
  readOnly: boolean;
  errors: FieldErrors<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  control: Control<T>;
  setValue: (name: any, value: any, shouldValidate?: boolean) => void;
  setData: (key: string, value: string | number | null) => void;
}

const StudiesConfirmationForm = <T extends FieldValues>({
  scholarshipData,
  scholarshipMetadata,
  readOnly,
  provinces,
  errors,
  schema,
  control,
  setValue,
  setData,
}: StudiesConfirmationFormProps<T>) => {
  const {
    educational_type_confirm_code,
    professional_group_id_confirm,
    educational_level_id_confirm,
    educational_type_id_confirm,
    educational_type,
    educational_type_code,
    professional_group,
    professional_group_id,
    course_name,
    scholarship_course_id_confirm,
    educational_level,
    confirmed_academy_code,
    confirmed_academy_name,
    confirmed_academy_nature,
    scholarshipType,
  } = scholarshipData;

  const { educationalLevels, educationalTypes, professionalGroups, courses } = scholarshipMetadata;

  useEffect(() => {
    if (confirmed_academy_code) {
      setValue('confirmed_academy_code', confirmed_academy_code);
    }
  }, [confirmed_academy_code, setValue]);

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setData(target.name, target.value);
      if (target.name === 'educational_type_id_confirm') {
        const et_code = educationalTypes.find((et: IdCodeNamePair) => et.id === target.value)?.code;
        setData('educational_type_confirm_code', et_code || '');
      }
    }
  };

  const professional_group_filtered = professionalGroups.filter(
    (pg: IdCodeNamePair) =>
      pg.code === (educational_type_confirm_code ? educational_type_confirm_code : educational_type_code),
  );

  const courses_filtered = courses.filter(
    (pg: IdCodeNamePair) =>
      pg.code?.toString() ===
      (professional_group_id_confirm ? professional_group_id_confirm?.toString() : professional_group_id?.toString()),
  );

  const renderStudyCenterNature = (): JSX.Element => {
    return (
      <SelectController
        control={control}
        errors={errors}
        schema={schema}
        size="50"
        name="confirmed_academy_nature"
        defaultValue={confirmed_academy_nature || ''}
        label={tScholarshipKey('¿Cuál es la titularidad del centro?')}
        disabled
      >
        {[...StudyCenterNature.toArray(), ''].map(nature => (
          <MenuItem key={nature} value={nature}>
            {tScholarshipKey(nature)}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  return (
    <FormContainer
      title={tScholarshipKey('Confirmación de Estudios')}
      subtitle={tScholarshipKey('subtitle-confirmacion-estudios')}
      className={styles.containerWithRadio}
    >
      <TextInputController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        disabled
        label={tScholarshipKey('Curso previsto a realizar')}
        name="educational_level"
        defaultValue={educational_level}
      />
      <SelectController
        control={control}
        errors={errors}
        size="50"
        schema={schema}
        key="educational_level_id_confirm"
        name="educational_level_id_confirm"
        disabled={readOnly}
        defaultValue={educational_level_id_confirm}
        label={tScholarshipKey('Curso realizado')}
        onClick={e => handleSelectChange(e.target)}
      >
        {educationalLevels.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>

      <TextInputController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        disabled
        label={tScholarshipKey('Categoría de curso prevista')}
        name="educational_type"
        defaultValue={educational_type}
      />

      <SelectController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        key="educational_type_id_confirm"
        name="educational_type_id_confirm"
        defaultValue={educational_type_id_confirm}
        label={tScholarshipKey('Categoría de curso realizada')}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {educationalTypes.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      <TextInputController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        disabled
        label={tScholarshipKey('Familia profesional prevista')}
        name="professional_group"
        defaultValue={professional_group}
      />
      <SelectController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        key="professional_group_id_confirm"
        name="professional_group_id_confirm"
        defaultValue={professional_group_id_confirm}
        label={tScholarshipKey('Familia profesional realizada')}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {professional_group_filtered.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      <TextInputController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        disabled
        label={tScholarshipKey('Nombre de los estudios que desea cursar')}
        name="course_name"
        defaultValue={course_name}
      />
      <SelectController
        control={control}
        schema={schema}
        errors={errors}
        size="50"
        key="scholarship_course_id_confirm"
        name="scholarship_course_id_confirm"
        defaultValue={scholarship_course_id_confirm}
        label={tScholarshipKey('nombre-estudios-realizados')}
        disabled={readOnly}
        onClick={e => handleSelectChange(e.target)}
      >
        {courses_filtered.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      <StudyCenterSelector
        readOnly={readOnly}
        scholarshipType={scholarshipType as ScholarshipType}
        provinces={provinces}
        educational_type_code={educational_type_confirm_code}
        academy_code={confirmed_academy_code}
        academy_name={confirmed_academy_name}
        setAcademyListItem={(selectedAcademyListItem: AcademySelectItem | null) => {
          const code = selectedAcademyListItem ? selectedAcademyListItem.code : null;
          const name = selectedAcademyListItem ? selectedAcademyListItem.name : null;
          const nature = selectedAcademyListItem ? selectedAcademyListItem.nature : null;
          setValue('confirmed_academy_code', code);
          setData('confirmed_academy_code', code);
          setValue('confirmed_academy_name', name);
          setData('confirmed_academy_name', name);
          setValue('confirmed_academy_nature', nature);
          setData('confirmed_academy_nature', nature);
        }}
      />
      {renderStudyCenterNature()}
    </FormContainer>
  );
};

const mapStateToProps = (state: AppState) => ({
  scholarshipMetadata: selectGetScholarshipMetadata(state),
  provinces: selectGenericMetadata(state).provinces,
});

const mapDispatchToProps = (dispatch: any) => ({
  setData: (key: string, value: string | number | null): void => dispatch(scholarshipSetData(key, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudiesConfirmationForm);
