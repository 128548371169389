import { IdTypeCodeNamePair } from '../../../../../redux/common/definitions';
import {
  KeepStudyingOption,
  ScholarshipAbandonmentReasonsType,
  ScholarshipFutureActionsType,
} from '../../../../../redux/scholarship/definitions';
import ScholarshipFutureActionCode from '../../../../../types/Scholarships/ScholarshipFutureActionCode.';

export function useAcademicResultViewModel() {
  return {
    filterScholarshipAbandonmentReasonsByType: (
      reasons: IdTypeCodeNamePair<ScholarshipAbandonmentReasonsType>[],
      type: ScholarshipAbandonmentReasonsType,
    ) => reasons.filter(reason => reason.type === type),
    scholarshipAbandonmentReasonsForKeepStudying: (
      reasons: IdTypeCodeNamePair<ScholarshipAbandonmentReasonsType>[],
      keep_studying: number,
    ) =>
      reasons.filter(reason =>
        keep_studying === KeepStudyingOption.NO
          ? reason.type === ScholarshipAbandonmentReasonsType.NO
          : keep_studying === KeepStudyingOption.FIN
          ? reason.type !== ScholarshipAbandonmentReasonsType.NO
          : false,
      ),
    allowAbandonmentReasonsSelect: (
      futureActions: IdTypeCodeNamePair<ScholarshipFutureActionsType>[],
      scholarship_future_action_id: number | null,
    ): boolean => {
      if (!scholarship_future_action_id) {
        return false;
      }
      const futureActionCode = ScholarshipFutureActionCode.findCode(futureActions, scholarship_future_action_id);
      if (!futureActionCode) {
        return false;
      }
      return futureActionCode.isNoContinuaYAbandona();
    },
  };
}
