import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import FormContainer from '../../../../components/Forms/FormContainer';
import TextInputController from '../../../../components/Inputs/TextInputController/TextInputController';
import SelectController from '../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../helpers/translate';
import { IdNamePair } from '../../../../redux/common/definitions';
import { ScholarshipFollow, ScholarshipMetadata } from '../../../../redux/scholarship/definitions';
import styles from './followForm.module.scss';

interface SocialImpactProps<T extends FieldValues> {
  scholarshipFollow: ScholarshipFollow;
  scholarshipMetadata: ScholarshipMetadata;
  readOnly?: boolean;
  errors: FieldErrors<T> | any;
  control: Control<T>;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  setDataFollow: (subKey: string, value: string | number | null) => void;
}

const SocialImpact = <T extends FieldValues>({
  readOnly,
  scholarshipMetadata,
  scholarshipFollow,
  errors,
  control,
  schema,
  setDataFollow,
}: SocialImpactProps<T>): ReactElement => {
  const {
    scholarship_student_changes_id,
    scholarship_personal_skills_id,
    per_scholarship_academic_levels_id,
    scholarship_student_personal_changes_reason_others,
    scholarship_personal_skills_reason_others,
  } = scholarshipFollow;
  const { studentChangePersonalReasons, satisfactionLeves, studentSatisfactions } = scholarshipMetadata;

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setDataFollow(target.name, target.value);
    }
  };

  return (
    <FormContainer title={tScholarshipKey('Impacto personal y social')}>
      <SelectController
        control={control}
        name="scholarship_student_changes_id"
        label={tScholarshipKey('cambios-personales-curso')}
        defaultValue={scholarship_student_changes_id}
        size="55"
        disabled={readOnly}
        validator={!readOnly}
        onClick={e => handleSelectChange(e.target)}
        schema={schema}
        errors={errors}
      >
        {studentChangePersonalReasons.map((element: IdNamePair) => (
          <MenuItem key={element.id} value={element.id}>
            {element.name}
          </MenuItem>
        ))}
      </SelectController>
      {scholarship_student_changes_id === 5 && (
        <TextInputController
          control={control}
          name="scholarship_student_personal_changes_reason_others"
          label={tScholarshipKey('Explica el caso')}
          defaultValue={scholarship_student_personal_changes_reason_others}
          size="100"
          schema={schema}
          validator={!readOnly}
          errors={errors}
          multiline
          rows={3}
          rowsMax={3}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            setDataFollow('scholarship_student_personal_changes_reason_others', e.target.value)
          }
        />
      )}
      <div className={styles.containerQuestion}>
        <SelectController
          control={control}
          name="per_scholarship_academic_levels_id"
          label={tScholarshipKey('nivel-satisfacción-formativa')}
          defaultValue={per_scholarship_academic_levels_id}
          size="55"
          disabled={readOnly}
          schema={schema}
          validator={!readOnly}
          errors={errors}
          onClick={e => handleSelectChange(e.target)}
        >
          {satisfactionLeves.map((element: IdNamePair) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          ))}
        </SelectController>
        <SelectController
          control={control}
          name="scholarship_personal_skills_id"
          label={tScholarshipKey('¿Porqué tiene este nivel de satisfacción?')}
          defaultValue={scholarship_personal_skills_id}
          size="55"
          disabled={readOnly}
          onClick={e => handleSelectChange(e.target)}
          validator={!readOnly}
          schema={schema}
          errors={errors}
        >
          {studentSatisfactions.map((element: IdNamePair) => (
            <MenuItem key={element.id} value={element.id}>
              {element.name}
            </MenuItem>
          ))}
        </SelectController>
      </div>
      {scholarship_personal_skills_id === 9 && (
        <TextInputController
          control={control}
          name="scholarship_personal_skills_reason_others"
          label={tScholarshipKey('Explica el caso')}
          defaultValue={scholarship_personal_skills_reason_others}
          size="100"
          schema={schema}
          disabled={readOnly}
          validator={!scholarship_personal_skills_reason_others}
          errors={errors}
          multiline
          rows={3}
          rowsMax={3}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
            setDataFollow('scholarship_personal_skills_reason_others', e.target.value)
          }
        />
      )}
    </FormContainer>
  );
};

export default SocialImpact;
