import { tKey, tScholarshipKey } from '../../helpers/translate';

export interface RadioOptions {
  value: string | number;
  color?: 'primary' | 'secondary';
  label: string;
  checked?: boolean;
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
  hiddenT?: boolean;
  show?: boolean;
}

export const yesNoOptions: RadioOptions[] = [
  { value: '1', label: 'Sí' },
  { value: '0', label: 'No' },
];

export const yesNoTranslatedOptions = (): RadioOptions[] => [
  { value: '1', label: tKey('Sí') },
  { value: '0', label: tKey('No') },
];

export const keepStudyingOptions = (): RadioOptions[] => [
  { value: '1', label: tKey('Sí') },
  { value: '0', label: tKey('No') },
  { value: '2', label: tScholarshipKey('keep_studying.2') },
];

export const zeroToFiveOptions = [
  { value: '0', label: '0', hiddenT: true },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

export const zeroToTenOptions = [
  { value: '0', label: '0', hiddenT: true },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

export const zeroToFiveWithLabelOptions: RadioOptions[] = [
  { value: '0', label: '0', hiddenT: true },
  { value: '1', label: '1', labelPlacement: 'top' },
  { value: '2', label: '2', labelPlacement: 'top' },
  { value: '3', label: '3', labelPlacement: 'top' },
  { value: '4', label: '4', labelPlacement: 'top' },
  { value: '5', label: '5', labelPlacement: 'top' },
];

export const zeroToFiveWithoutLabelOptions: RadioOptions[] = [
  { value: '0', label: '0', hiddenT: true },
  { value: '1', label: '' },
  { value: '2', label: '' },
  { value: '3', label: '' },
  { value: '4', label: '' },
  { value: '5', label: '' },
];

export const AtoCLabelOptions: RadioOptions[] = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
];
