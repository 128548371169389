import { MenuItem } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form';
import { Control, FieldErrors, FieldValues } from 'react-hook-form/dist/types';
import { ObjectSchema, Shape } from 'yup';
import TextInputController from '../../../../../components/Inputs/TextInputController/TextInputController';
import RadioButtonGroup from '../../../../../components/RadioButtons/RadioButtonGroup';
import { keepStudyingOptions } from '../../../../../components/RadioButtons/RadioButtonOptions';
import SelectController from '../../../../../components/Select/SelectController';
import { tScholarshipKey } from '../../../../../helpers/translate';
import { IdTypeCodeNamePair } from '../../../../../redux/common/definitions';
import {
  KeepStudyingOption,
  ScholarshipAbandonmentReasonsType,
  ScholarshipFollow,
  ScholarshipFutureActionsType,
  ScholarshipMetadata,
} from '../../../../../redux/scholarship/definitions';
import FollowUpCode from '../../../../../types/Scholarships/followUpCode';
import ScholarshipFutureActionCode from '../../../../../types/Scholarships/ScholarshipFutureActionCode.';
import styles from '../followForm.module.scss';
import { useAcademicResultViewModel } from './useAcademicResultViewModel';

interface AcademicResultReasonsViewProps<T extends FieldValues> {
  scholarshipFollow: ScholarshipFollow;
  scholarshipMetadata: ScholarshipMetadata;
  readOnly: boolean;
  errors: FieldErrors<T> | any;
  control: Control<T>;
  scholarshipType: string;
  followUpCode: FollowUpCode;
  schema: ObjectSchema<T> | ObjectSchema<Shape<object, any>>;
  register?: ReturnType<typeof useForm>['register'];
  setDataFollow: (subKey: string, value: string | number | null) => void;
  setValue: (name: string, value: string | number | null, shouldValidate?: boolean) => void;
}

const AcademicResultReasonsView = <T extends FieldValues>({
  scholarshipFollow,
  scholarshipMetadata,
  readOnly,
  errors,
  control,
  scholarshipType,
  followUpCode,
  schema,
  register,
  setDataFollow,
  setValue,
}: AcademicResultReasonsViewProps<T>): ReactElement => {
  const {
    keep_studying,
    scholarship_abandoment_reasons_id,
    scholarship_future_actions_id,
    scholarship_abandoment_reasons_others,
  } = scholarshipFollow;
  const { scholarshipAbandonmentReasons, scholarshipFutureActions } = scholarshipMetadata;

  const {
    filterScholarshipAbandonmentReasonsByType,
    scholarshipAbandonmentReasonsForKeepStudying,
    allowAbandonmentReasonsSelect,
  } = useAcademicResultViewModel();

  const handleSelectChange = (target: any) => {
    if (target.name) {
      setDataFollow(target.name, target.value);
    }
  };

  const handleFutureActionsSelectChange = (target: any) => {
    if (target.name) {
      setDataFollow(target.name, target.value);

      const futureActionCode = ScholarshipFutureActionCode.findCode(scholarshipFutureActions, target.value);
      if (futureActionCode && !futureActionCode.isNoContinuaYAbandona()) {
        setDataFollow('scholarship_abandoment_reasons_id', null);
        setDataFollow('scholarship_abandoment_reasons_others', null);
        setValue('scholarship_abandoment_reasons_id', null);
        setValue('scholarship_abandoment_reasons_others', null);
      }
    }
  };
  const scholarshipAbandonmentReasonsFiltered = followUpCode.isLastFollowUp()
    ? filterScholarshipAbandonmentReasonsByType(scholarshipAbandonmentReasons, ScholarshipAbandonmentReasonsType.NO)
    : scholarshipAbandonmentReasonsForKeepStudying(scholarshipAbandonmentReasons, keep_studying);

  const futureActionsSelector = (): JSX.Element => {
    return (
      <SelectController
        control={control}
        name="scholarship_future_actions_id"
        label={tScholarshipKey(`follow_up.${scholarshipType}.scholarship_future_actions_id`)}
        defaultValue={scholarship_future_actions_id}
        disabled={readOnly}
        schema={schema}
        validator={!readOnly}
        errors={errors}
        onClick={e => handleFutureActionsSelectChange(e.target)}
      >
        {scholarshipFutureActions.map((element: IdTypeCodeNamePair<ScholarshipFutureActionsType>) => (
          <MenuItem key={element.id} value={element.id}>
            {tScholarshipKey(`future_action.${element.code}`)}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const keepStudyingRadioButtons = (): JSX.Element => {
    const label = tScholarshipKey(
      `follow_up.${scholarshipType}.${followUpCode
        .followUpType()
        .value()
        .toLowerCase()}.keep_studying`,
    );
    return (
      <RadioButtonGroup
        name="keep_studying"
        errors={errors.keep_studying}
        disabled={readOnly}
        questionText={label}
        options={keepStudyingOptions()}
        value={keep_studying?.toString() || ''}
        register={register}
        onChange={e => {
          setDataFollow('keep_studying', +e.target.value);
        }}
      />
    );
  };

  const renderFirstQuestion = (): JSX.Element => {
    if (followUpCode.isLastFollowUp()) {
      return futureActionsSelector();
    }
    return keepStudyingRadioButtons();
  };

  const reasonsSelectorDisabled = (): boolean => {
    if (readOnly) {
      return true;
    }
    if (followUpCode.isLastFollowUp()) {
      return !allowAbandonmentReasonsSelect(scholarshipFutureActions, scholarship_future_actions_id);
    }
    return keep_studying !== KeepStudyingOption.NO;
  };

  const renderReasonsSelector = (): JSX.Element => {
    return (
      <SelectController
        control={control}
        name="scholarship_abandoment_reasons_id"
        label={tScholarshipKey(`follow_up.${scholarshipType}.scholarship_abandoment_reasons`)}
        defaultValue={scholarship_abandoment_reasons_id}
        schema={schema}
        errors={errors}
        onClick={e => handleSelectChange(e.target)}
        disabled={reasonsSelectorDisabled()}
        validator={!reasonsSelectorDisabled()}
      >
        {scholarshipAbandonmentReasonsFiltered.map((element: IdTypeCodeNamePair<ScholarshipAbandonmentReasonsType>) => (
          <MenuItem key={element.id} value={element.id}>
            {tScholarshipKey(`abandonment_reason.${element.code}`)}
          </MenuItem>
        ))}
      </SelectController>
    );
  };

  const renderAbandonmentReasonsOthers = (): JSX.Element | undefined => {
    if (
      keep_studying === KeepStudyingOption.NO ||
      allowAbandonmentReasonsSelect(scholarshipFutureActions, scholarship_future_actions_id)
    ) {
      return (
        <div className={styles.containerFullGridRow}>
          <TextInputController
            control={control}
            name="scholarship_abandoment_reasons_others"
            label={tScholarshipKey('follow_up.scholarship_abandoment_reasons_others')}
            defaultValue={scholarship_abandoment_reasons_others}
            fullWidth
            schema={schema}
            errors={errors}
            multiline
            rows={3}
            validator={!readOnly}
            rowsMax={3}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) =>
              setDataFollow('scholarship_abandoment_reasons_others', e.target.value)
            }
          />
        </div>
      );
    }
    return undefined;
  };

  return (
    <div className={styles.container}>
      {renderFirstQuestion()}
      {renderReasonsSelector()}
      {renderAbandonmentReasonsOthers()}
    </div>
  );
};

export default AcademicResultReasonsView;
